import React, { useState, useEffect, useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Switch, Collapse, Button } from 'antd';



import Select from 'react-select';

import { DataContext } from '../../init/getData';

const { Panel } = Collapse;

function ProductSetting() {

  const { api, userData, refreshSetting, settingData } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;

  const [loadingEffect, setLoadingEffect] = useState(false);

  //Types
  const [simpleProduct, setSimpleProduct] = useState(false);
  const [serializedProduct, setSerializedProduct] = useState(false);
  const [variableProduct, setVariableProduct] = useState(false);
  const [compoundProduct, setCompoundProduct] = useState(false);
  const [productionProduct, setProductionProduct] = useState(false);

  //General
  const [useProductColor, setUseProductColor] = useState(false);
  const [useImage, setUseImage] = useState(false);
  const [useSecondaryUnit, setUseSecondaryUnit] = useState(false);

  useEffect(() => {
    if (settingData) {
      setSimpleProduct(settingData.simpleProduct)
      setSerializedProduct(settingData.serializedProduct)
      setVariableProduct(settingData.variableProduct)
      setCompoundProduct(settingData.compoundProduct)
      setProductionProduct(settingData.productionProduct)

      setUseProductColor(settingData.useProductColor)
      setUseImage(settingData.useImage)
      setUseSecondaryUnit(settingData.useSecondaryUnit)
    }
  }, [settingData]);


  const handleSubmit = async () => {

    setLoadingEffect(true);

    let settings = {
      simpleProduct,
      serializedProduct,
      variableProduct,
      compoundProduct,
      productionProduct,

      useProductColor,
      useImage,
      useSecondaryUnit,
    }

    let res = await api.post(`/setting/edit`, JSON.stringify(settings))
    if (res.data.status) {
      toast.success(res.data.message);
      refreshSetting();
    }
    else {
      toast.error(res.data.message);
    }
    setLoadingEffect(false);
  }



  const TypesForm = (
    <>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Simple Product</Form.Label>
        <Col sm={9}>
          <Switch defaultChecked onChange={() => setSimpleProduct(!simpleProduct)} checked={simpleProduct} />
        </Col>
      </Form.Group>




      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Variable Product</Form.Label>
        <Col sm={9}>
          <Switch defaultChecked onChange={() => setVariableProduct(!variableProduct)} checked={variableProduct} />
        </Col>
      </Form.Group>






      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Compound Product</Form.Label>
        <Col sm={9}>
          <Switch defaultChecked onChange={() => setCompoundProduct(!compoundProduct)} checked={compoundProduct} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Production Product</Form.Label>
        <Col sm={9}>
          <Switch defaultChecked onChange={() => setProductionProduct(!productionProduct)} checked={productionProduct} />
        </Col>
      </Form.Group>


      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Serialized Product</Form.Label>
        <Col sm={9}>
          <Switch defaultChecked onChange={() => setSerializedProduct(!serializedProduct)} checked={serializedProduct} />
        </Col>
      </Form.Group>


    </>
  )

  const GeneralForm = (
    <>
      {/*  Default Date */}
      <Row className="mb-3">
        <Form.Label column sm={2}>
          User Colors
        </Form.Label>
        <Col sm={4}>
        <select className="form-control" value={useProductColor} onChange={(e) => setUseProductColor(e.target.value === 'true')}>
            <option value="true"> Yes </option>
            <option value="false"> No </option>
          </select>
        </Col>
      </Row>

      <Row className="mb-3">
        <Form.Label column sm={2}>
          Use Images
        </Form.Label>
        <Col sm={4}>
        <select className="form-control" value={useImage} onChange={(e) => setUseImage(e.target.value === 'true')}>
            <option value="true"> Yes </option>
            <option value="false"> No </option>
          </select>
        </Col>
      </Row>


      <Row className="mb-3">
        <Form.Label column sm={2}>
          Use Secondary Unit
        </Form.Label>
        <Col sm={4}>
        <select className="form-control" value={useSecondaryUnit} onChange={(e) => setUseSecondaryUnit(e.target.value === 'true')}>
            <option value="true"> Yes </option>
            <option value="false"> No </option>
          </select>
        </Col>
      </Row>

    </>
  )

  const items = [
    {
      key: '1',
      label: 'Types',
      children: <>{TypesForm}</>,
    },
    {
      key: '2',
      label: 'General',
      children: <>{GeneralForm}</>,
    },

  ];


  return (
    <div
      className="card" style={{ userSelect: 'none', height: '86vh', display: 'flex', flexDirection: 'row', overflowY: 'scroll' }}
    >

      <Collapse className="custom-collapse" style={{ width: '100%' }} defaultActiveKey={['1']}>
        {items.map(item => (
          <Panel header={item.label} key={item.key} style={{ backgroundColor: '#d8e1e8' }}>
            {item.children}
          </Panel>
        ))}
      </Collapse>

      <Button type="primary" size="large" loading={loadingEffect} onClick={handleSubmit}

        style={{ marginLeft: '10px', backgroundColor: '#1d7790' }}>Save</Button>



    </div>
  );
}

export default ProductSetting;
