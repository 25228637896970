import JsBarcode from 'jsbarcode';

import moment from 'moment';
import { numberFormatter } from './numberFormatter';

const dataFormat = require("../dynamic/date");
const dateNow = new Date(dataFormat.getDateFormatted());

const currentDate = moment.utc(dateNow).format('DD-MM-YY hh:mm A');

const print = async (printData) => {


  const { cart, total, settingData, tva, discount, userName, invoiceId, customer, delivery, devData } = printData;

  let totalTvapaid = 0;
  for (const product of cart) {
    if (product.isTva) {
      totalTvapaid += parseFloat((product.totalPrice * settingData.tva / 100))
    }
  }

  const form = document.createElement("formid");


  // Generate barcode for invoiceId
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, invoiceId, { format: 'CODE128', height: 20, displayValue: false, });

  // Convert canvas to data URL (PNG)
  const barcodeDataUrl = canvas.toDataURL();

  form.innerHTML = `

   <div>
        <p style="text-align: center; font-weight: bold;font-size: 20px;">${settingData.printingName}</p>
        <p style="text-align: center; font-size: 13px;">${settingData.printingAddress}</p>
        <p style="text-align:center;"><label >${settingData.printingPhone}</label></p>
   
        <div style="border-bottom: 1px dashed #000; margin: 10px 0;"></div>

        <div style="display: flex; justify-content: space-between;">
            <a style="text-align:left; font-size: 13px; margin-top: 0;">Served By: ${userName}</a>
            <a style="text-align:right; font-size: 13px; margin-bottom: 0;">Invoice# : ${invoiceId}</a>
        </div>
        
        <div style="border-top: 1px dashed #000; margin: 10px 0;"></div>
  
        ${customer && customer.value ?
      `<p style="text-align:left; font-size: 13px;">Customer: &nbsp; &nbsp;${customer.name}</p>
          <p style="text-align:left; font-size: 13px;">Phone: &nbsp; &nbsp;${customer.phone}</p>
          <p style="text-align:left; font-size: 13px;">Address: &nbsp; &nbsp;${customer.address}</p>
          <div style="border-top: 1px dashed #000;margin: 10px 0;"></div>` : ``
    }

        ${delivery && delivery.value ?
      `<div style="display: flex; justify-content: space-between;">
          <a style="text-align:left; font-size: 13px; margin-top: 0;">${devData.deliveryName}: ${delivery.name}</a>
          <a style="text-align:right; font-size: 13px; margin-bottom: 0;">Phone: ${delivery.phone}</a>
          </div>
          <div style="border-top: 1px dashed #000;margin: 10px 0;"></div>` : ``
    }

    <table style="width: 100%">
      <thead>
        <tr>
          <th style='float: left'>Item</th>
          <th>Qnty</th>
          <th>Dis</th>
          <th>Price</th>
        </tr>
      </thead>
      
      <tbody>
        ${cart.map((product) => `
          <tr key="${product.id}">
            <td>${product.name}</td>
            <td style="text-align: center;">x${product.quantity}</td>
            <td style="text-align: center;">${numberFormatter(product.discount, 2)}% </td>
            <td style="text-align: center">${numberFormatter(product.totalPrice, 2)}</td>
          </tr>
          ${product.details ? `
          <tr key="${product.id + 999}">
            <td>${product.details}</td>
          </tr>
        ` : ''}
        `).join('')}
      </tbody>
    </table>

    <div style="border-top: 1px dashed #000; margin: 10px 0;"></div>

    ${settingData.defaultTva ?
      `
      <p style="text-align:center; font-size: 10px;">TVA: &nbsp; &nbsp;${settingData.tva}  %</p>
      <p style="text-align:center; font-size: 10px;">Total Tva: &nbsp; &nbsp;${numberFormatter(totalTvapaid, 2)} ${settingData.currency} </p>
      ` : ''
    }

    ${discount !== 0 ?
      `<p style="text-align:center; font-size: 13px;">Discount: &nbsp; &nbsp;${numberFormatter(discount, 2)} %</p>` :
      ''
    }
    <p style="text-align:center;">Total: &nbsp; &nbsp;${numberFormatter(total, 2)} ${settingData.currency}</p>

    ${settingData.isRated ?
      `<p style="text-align:center;">&nbsp; &nbsp;${numberFormatter(total * settingData.rate, 2)} ${settingData.ratedCurrency}</p>` :
      ''
    }

    ${settingData.showRateOnInvoice && settingData.rateMultiply ?
      `<p style="text-align:center;">&nbsp; &nbsp;${numberFormatter(total * settingData.rate, 2)} ${settingData.ratedCurrency}</p>` :
      ''
    }

    ${settingData.showRateOnInvoice && !settingData.rateMultiply ?
      `<p style="text-align:center;">&nbsp; &nbsp;${numberFormatter(total / settingData.rate, 2)} ${settingData.ratedCurrency}</p>` :
      ''
    }

    <br>

    <p style="text-align: center; margin-bottom: 0px;">${currentDate}</p>
    <img src="${barcodeDataUrl}" alt="Barcode" style="display: block; margin: 10px auto;">
    <p style="text-align: center; margin-bottom: 100px;">Thank you for you visit !!</p>
    </div>
  `;



  const newWindow = window.open();
  newWindow.document.body.appendChild(form);
  if (settingData.printDirect) {
    //webContents.print({silent: false, printBackground: false, deviceName: ''})

  } else {
    await new Promise(resolve => setTimeout(resolve, 10));
    newWindow.print();
  }

  newWindow.close();





};

const prints = async (printData) => {

  const { cart, total, settingData, tva, discount, userName, invoiceId, customer, delivery } = printData;

  let totalTvapaid = 0;
  for (const product of cart) {
    if (product.isTva) {
      totalTvapaid += parseFloat((product.totalPrice * settingData.tva / 100))
    }
  }

  const form = document.createElement("formid");


  // Generate barcode for invoiceId
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, invoiceId, { format: 'CODE128', height: 20, displayValue: false, });

  // Convert canvas to data URL (PNG)
  const barcodeDataUrl = canvas.toDataURL();

  form.innerHTML = `

   <div>
        <p style="text-align: center; font-weight: bold;font-size: 20px;">${settingData.printingName}</p>
        <p style="text-align: center; font-size: 13px;">${settingData.printingAddress}</p>
        <p style="text-align:center;"><label >${settingData.printingPhone}</label></p>
   
        <div style="border-bottom: 1px dashed #000; margin: 10px 0;"></div>

        <div style="display: flex; justify-content: space-between;">
            <a style="text-align:left; font-size: 13px; margin-top: 0;">Served By: ${userName}</a>
        </div>
        
        <div style="border-top: 1px dashed #000; margin: 10px 0;"></div>
  
        ${customer && customer.value ?
      `<p style="text-align:left; font-size: 13px;">Customer: &nbsp; &nbsp;${customer.name}</p>
          <p style="text-align:left; font-size: 13px;">Phone: &nbsp; &nbsp;${customer.phone}</p>
          <p style="text-align:left; font-size: 13px;">Address: &nbsp; &nbsp;${customer.address}</p>
          <div style="border-top: 1px dashed #000;margin: 10px 0;"></div>` : ``
    }

        ${delivery && delivery.value ?
      `<div style="display: flex; justify-content: space-between;">
          <a style="text-align:left; font-size: 13px; margin-top: 0;">Delivery: ${delivery.name}</a>
          <a style="text-align:right; font-size: 13px; margin-bottom: 0;">Price: ${numberFormatter(delivery.price, 2)}${settingData.currency}</a>
          </div>
          <div style="border-top: 1px dashed #000;margin: 10px 0;"></div>` : ``
    }

    <table style="width: 100%">
      <thead>
        <tr>
          <th style='float: left'>Item</th>
          <th>Qnty</th>
          <th>Dis</th>
          <th>Price</th>
        </tr>
      </thead>
      
      <tbody>
        ${cart.map((product) => `
          <tr key="${product.id}">
            <td>${product.name}</td>
            <td style="text-align: center;">x${product.quantity}</td>
            <td style="text-align: center;">${numberFormatter(product.discount, 2)}% </td>
            <td style="text-align: center">${numberFormatter(product.totalPrice, 2)}</td>
          </tr>
          ${product.details ? `
          <tr key="${product.id + 999}">
            <td>${product.details}</td>
          </tr>
        ` : ''}
        `).join('')}
      </tbody>
    </table>

    <div style="border-top: 1px dashed #000; margin: 10px 0;"></div>

    ${settingData.defaultTva ?
      `
      <p style="text-align:center; font-size: 10px;">TVA: &nbsp; &nbsp;${settingData.tva}  %</p>
      <p style="text-align:center; font-size: 10px;">Total Tva: &nbsp; &nbsp;${numberFormatter(totalTvapaid, 2)} ${settingData.currency} </p>
      ` : ''
    }

    <p style="text-align:center; font-size: 13px;">Discount: &nbsp; &nbsp;${numberFormatter(discount, 2)} %</p>
    <p style="text-align:center;">Total: &nbsp; &nbsp;${numberFormatter(total, 2)} ${settingData.currency}</p>

    ${settingData.isRated ?
      `<p style="text-align:center;">&nbsp; &nbsp;${numberFormatter(total * settingData.rate, 2)} ${settingData.ratedCurrency}</p>` :
      ''
    }

    ${settingData.isRated && settingData.rateMultiply ?
      `<p style="text-align:center;">&nbsp; &nbsp;${numberFormatter(total * settingData.rate, 2)} ${settingData.ratedCurrency}</p>` :
      ''
    }

    ${!settingData.isRated && !settingData.rateMultiply ?
      `<p style="text-align:center;">&nbsp; &nbsp;${numberFormatter(total / settingData.rate, 2)} ${settingData.ratedCurrency}</p>` :
      ''
    }


  
    <br> 
    <p style="text-align: center; margin-bottom: 0px;">${currentDate}</p>
    <p style="text-align: center; margin-bottom: 100px;">Thank you for you visit !!</p>
    </div>
  `;



  const newWindow = window.open();
  newWindow.document.body.appendChild(form);
  if (settingData.printDirect) {
    //webContents.print({silent: false, printBackground: false, deviceName: ''})

  } else {
    await new Promise(resolve => setTimeout(resolve, 10));
    newWindow.print();
  }

  newWindow.close();





};

const printProductSalesReport = (data) => {
  //console.log(data)
}

const printBarcode = async (printData) => {

  const { barcode, barCodeSize } = printData;

  const form = document.createElement("formid");

  // Generate barcode for invoiceId
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, barcode, { format: 'CODE128', height: 150, displayValue: true, });

  // Convert canvas to data URL (PNG)
  const barcodeDataUrl = canvas.toDataURL();

  form.innerHTML = `
 <div>
 <img src="${barcodeDataUrl}" width="${barCodeSize}px" alt="Barcode" style="display: block; margin: 10px auto;">
  </div>
`;



  const newWindow = window.open();
  newWindow.document.body.appendChild(form);

  await new Promise(resolve => setTimeout(resolve, 10));
  newWindow.print();


  newWindow.close();





};

const printLabel = async (printData) => {

  const { product, settingData, size } = printData;

  const form = document.createElement("formid");

  // Generate barcode for invoiceId
  //const canvas = document.createElement('canvas');
  //JsBarcode(canvas, barcode, { format: 'CODE128', height: 50, displayValue: true, });
  //const barcodeDataUrl = canvas.toDataURL();

  const name = product.name;
  const price = product.price;

  form.innerHTML = `
    <div>
      <p style="font-size: ${size}px">${name} ${numberFormatter(price, 2)}${settingData.currency}</p>
    </div>
  `;


  const newWindow = window.open();
  newWindow.document.body.appendChild(form);

  await new Promise(resolve => setTimeout(resolve, 10));
  newWindow.print();


  newWindow.close();





};

export {
  print,
  printProductSalesReport,
  printBarcode,
  printLabel,
};

