import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import Select from 'react-select';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { print } from "../../dynamic/print";

import AccountAdd from '../../accountTab/account/crud/accountAdd';
import AccountEdit from '../../accountTab/account/crud/accounEdit';
import Exchange from './exchange';

import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';

import { FcPlus } from "react-icons/fc";
import { BiEdit } from "react-icons/bi";

import { DataContext } from '../../init/getData';
import { numberFormatter } from '../../dynamic/numberFormatter';

const PayNow = (props) => {

  const { userData, refAccountData, accountData, devData, serverApi, localApi } = useContext(DataContext);
  const createdBy = parseInt(userData.id);
  const userName = userData.name;
  const userRole = userData.role;
  const wareHouseSaleId = userData.role.wareHouseSaleId;

  const [account, setAccount] = useState([]);


  let api;
  if (!devData?.isServer && !devData?.isCloud) {
    api = serverApi;
  } else {
    api = localApi;
  }


  let {
    showModal, toggleModal, settingData,
    cart, isPrint, finalDiscount, isTva,
    editOrderId, finalNetTotal,
    closePaymentDone, selectedTab, removeTab,
    isCredit, isOut
  } = props;

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [addCustomerModal, setAddCustomerModal] = useState(false)
  const [editCustomerModal, setEditCustomerModal] = useState(false)
  const [delivery, setDelivery] = useState({ value: null, label: 'none' });
  const [customer, setCustomer] = useState({ value: null, label: 'none' });
  const [idRow, setIdRow] = useState('');

  const [loadingEffect, setLoadingEffect] = useState(false);


  const selectCustomer = (customer) => {
    setCustomer(customer);
  }

  const addModal = async () => {
    setIdRow({});
    resetForm();
    if (showAddModal) {
      await refAccountData();
    }
    setShowAddModal(!showAddModal);
  }

  const editModal = async (e) => {
    if (!showEditModal) {
      const editCustomer = { ...customer };
      delete editCustomer.value;
      delete editCustomer.label;
      setAccount(editCustomer);
    } else {
      await refAccountData();
    }
    setShowEditModal(!showEditModal);
  }


  const resetForm = () => {
    setAccount({
      createdBy: createdBy,
      type: 'Customer',
      address: "",
      details: "",
    })
  }

  // idRow Change
  useEffect(() => {
    setAccount(
      {
        name: idRow?.name || '',
        phone: idRow?.phone || '',
        address: idRow?.address || '',
        details: idRow?.details || '',
        createdBy: createdBy,
        type: 'Customer',
      }
    )
  }, [idRow]);

  // Generic handler for input changes
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value)
    }
    setAccount((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const closeModal = () => {
    toggleModal();
    setDelivery({ value: null, label: 'none' });
    setCustomer({ value: null, label: 'none' });
  }


  // customer Options for dropDown
  const customerOptions = accountData
    .filter(item => item.type === 'Customer')
    .map(item => ({
      value: item.id,
      id: item.id,
      label: `${item.name} - ${item.phone}`,
      name: item.name,
      phone: item.phone,
      address: item.address,
    }));


  const deliveryData = accountData?.filter(account => account.type === 'Delivery');
  // Delivery Options for dropDown
  const deliveryOptions = deliveryData.map(item => ({
    value: item.id,
    name: item.name,
    label: `${item.name} - ${item.phone}`,
    phone: item.phone,
  }));

  // Delivery Option
  deliveryOptions.push({
    value: null,
    label: 'none',
  });


  // Customer Option
  customerOptions.push({
    value: null,
    label: 'none',
  });

  // Place Order
  const placeOrder = async (type) => {



    // remove productId for offline

    if (isCredit && !customer.value) {
      toast.error('Please select a customer');
      return;
    }

    setLoadingEffect(true);
    let data = {};

    if (devData?.isCloud) {
      cart.forEach(item => {
        item.productIdCloud = item.productId;
        delete item.productId;
      });
    }



    const transaction = {
      createdBy: createdBy,
      wareHouseId: userData.role.wareHouseSaleId,
      accountId: customer.value,
      deliveryId: delivery.value,
      total: finalNetTotal,
      rate: settingData.rate,
      tva: isTva ? settingData.tva : 0,
      type: isOut ? 'Return' : 'Sale',
      isOut: isOut,
      isCredit: isCredit,
      isOrder: type == 'Order' ? true : false,
      discount: finalDiscount,
      isCloud: devData?.isCloud,
    };

    if (devData?.isCloud) {

      transaction.accountIdCloud = transaction.accountId;
      transaction.deliveryIdCloud = transaction.deliveryId;
      transaction.wareHouseIdCloud = transaction.wareHouseId;
      delete transaction.accountId;
      delete transaction.deliveryId;
      delete transaction.wareHouseId;

    }


    let res = await api.post(`/transaction/add`, JSON.stringify({ transaction, cart }));
    if (res.data.status) {
      toast.success(res.data.message);
      closePaymentDone();
      closeModal();
      setLoadingEffect(false);

      if (isPrint) {
        let printData = {
          cart: cart,
          total: finalNetTotal,
          settingData: settingData,
          discount: finalDiscount,
          userName: userName,
          invoiceId: res.data.id,
          customer: customer,
          delivery: delivery,
          devData: devData,
        }
        print(printData)
      }
    } else {
      toast.error('Failed, Contact Support');
      setLoadingEffect(false);
    }

  }



  const formView = (
    <div style={{ userSelect: 'none' }}>


      <Row className="mb-3">
        {/* Name  */}
        <Form.Group as={Col}>
          <Form.Label className='formLabel'>Name<span style={{ color: 'red' }}> *</span></Form.Label>
          <Form.Control type="text" name='name'
            value={account.name}
            onChange={handleInputChange}
            required
          />
        </Form.Group>


        {/* Phone  */}
        <Form.Group as={Col}>
          <Form.Label className='formLabel'>Phone<span style={{ color: 'red' }}> *</span></Form.Label>
          <Form.Control type="text" name='phone'
            value={account.phone}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

      </Row>


      <Row className="mb-3">
        {/* Address  */}
        <Form.Group as={Col}>
          <Form.Label className='formLabel'>Address<span style={{ color: 'red' }}> *</span></Form.Label>
          <Form.Control as="textarea" name='address'
            value={account.address}
            onChange={handleInputChange}
          />
        </Form.Group>


        {/* Details  */}
        <Form.Group as={Col}>
          <Form.Label className='formLabel'>Details<span style={{ color: 'red' }}> *</span></Form.Label>
          <Form.Control as="textarea" name='details'
            value={account.details}
            onChange={handleInputChange}
          />
        </Form.Group>

      </Row>



    </div >);



  return (
    <Modal show={showModal} size="lg" backdrop="static" onHide={toggleModal} >
      <Modal.Header style={{ background: "#39aaa5" }}>
        <Modal.Title style={{ color: "white" }}>{isCredit ? 'Credit' : 'Cash'} Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id='addForm'>


          <Row className="mb-3" style={{ marginTop: '2%' }}>

            {/* Customer  */}
            <Form.Group as={Col}>
              <Form.Label>Customer</Form.Label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '100%' }}>
                  <Select
                    options={customerOptions}
                    isSearchable={true}
                    placeholder="Select"
                    value={customer}
                    onChange={(customer) => selectCustomer(customer)}
                  />
                </div>
                <FcPlus size={30} style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => addModal()} />
                <BiEdit size={28} style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => editModal()} />

              </div>
            </Form.Group>








          </Row>



          {customer.value && (
            <Row className="mb-3" style={{ marginTop: '2%' }}>

              <Form.Group as={Col}>
                <Form.Label>Address</Form.Label>
                <textarea className="form-control" rows={1} value={customer.address} name="address" id="address" disabled></textarea>
              </Form.Group>

            </Row>

          )}


          <Row className="mb-3" style={{ marginTop: '2%' }}>

            {/* delivery  */}
            <Form.Group as={Col}>
              <Form.Label>Select {devData.deliveryName}</Form.Label>
              <Select
                options={deliveryOptions}
                isSearchable={true}
                placeholder="Select"
                value={delivery}
                onChange={setDelivery}
              />
            </Form.Group>
          </Row>

          {settingData.moneyExchange &&
            <Exchange finalNetTotal={finalNetTotal} />
          }



          {/* Total */}
          <Row className="mb-3" style={{ marginTop: '2%', justifyContent: 'center' }}>
            <Form.Group as={Col} style={{ display: 'flex', justifyContent: 'center' }}>
              {!settingData.isRated &&
                <p style={{ color: 'green', fontWeight: 'bold', fontSize: '25px', marginTop: '20px' }}>Total: {numberFormatter(finalNetTotal, 2)}  {settingData.currency}</p>
              }
              {settingData.isRated &&
                <>
                  <p style={{ color: 'green', fontWeight: 'bold', fontSize: '25px', marginTop: '20px', textAlign: 'center' }}> {numberFormatter(finalNetTotal, 2)}  {settingData.currency}  </p>
                  <p style={{ color: 'green', fontWeight: 'bold', fontSize: '25px', marginTop: '20px', textAlign: 'center' }}> &nbsp; || &nbsp;{numberFormatter((finalNetTotal * settingData.rate), 2)} {settingData.ratedCurrency}</p>
                </>
              }
            </Form.Group>
          </Row>


          <div className="modal-footer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button type="button" style={{ height: '80px', width: '20%', fontSize: 'large' }} className="btn btn-secondary" onClick={closeModal}>
              Close
            </button>

            {userRole.useOrderAction &&
              <button type="button" style={{ height: '80px', width: '20%', backgroundColor: '#1d7790', fontSize: 'large' }} className="btn btn-primary"
                onClick={() => placeOrder('Order')}>
                Order
                {loadingEffect && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                  style={{ marginLeft: '5px' }}></span>}
              </button>
            }

            {isCredit &&
              <button type="button" style={{ height: '80px', width: '20%', backgroundColor: '#dc3545', fontSize: 'large' }} className="btn btn-primary"
                onClick={() => placeOrder('Sale')}>
                Credit
                {loadingEffect && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                  style={{ marginLeft: '5px' }}></span>}
              </button>
            }

            {!isCredit &&
              <button type="button" style={{ height: '80px', width: '20%', backgroundColor: 'green', fontSize: 'large' }} className="btn btn-primary"
                onClick={() => placeOrder('Sale')}>
                Cash
                {loadingEffect && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                  style={{ marginLeft: '5px' }}></span>}
              </button>
            }

          </div>



        </Form>
      </Modal.Body>

      <AddModal
        showModal={showAddModal}
        toggleModal={addModal}
        formView={formView}
        idRow={idRow}
        table={'account'}
        title={'account'}
        size={'lg'}
        resetForm={resetForm}
        api={api}
        data={{ account }}
      />

      <EditModal
        showModal={showEditModal}
        toggleModal={editModal}
        formView={formView}
        idRow={idRow}
        table={'account'}
        title={'account'}
        size={'lg'}
        resetForm={resetForm}
        api={api}
        data={{ id: account.id, account }}
      />

    </Modal>
  )
}


export default PayNow;