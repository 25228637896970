import React, { useState, useEffect, useContext, useRef } from 'react';


import SimpleTable from '../../components/tables/simpleTable';

import TrashModal from '../../components/modals/trashModal';
import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';
import CartModal from '../../components/modals/cartModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import IsOrderFilter from '../../components/filters/isOrderFilter';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';
import DropDownFilter from '../../components/filters/dropDownFilter';
import { ConfigProvider, Card, Divider } from 'antd';

import { FcKindle } from "react-icons/fc";

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter, decimalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { typeFormatter, paymentTypeFormatter } from '../../components/formatters/defFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import { dateTableFormatter } from '../../dynamic/dateFormatter';
import { creditFormatter, debitFormatter } from '../../components/formatters/defFormatter';
import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs } from 'antd';

import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcRefresh, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

import { DataContext } from '../../init/getData';




function DeliveryTransaction(props) {

  let { type } = props;


  const { api, userData, settingData, accountData, wareHouseData, devData } = useContext(DataContext);
  const userRole = userData.role;
  const [isLoading, setIsLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [data, setData] = useState([]);
  const createdBy = parseInt(userData.id);
  const roundTo = settingData.roundTo;

  const defaultWareHouseId = userData?.role?.defaultWareHouseId;

  const table = 'transaction';
  const title = devData.deliveryName + ' Transactions';

  const reportRef = useRef(null);

  const today = new Date().toISOString().split('T')[0];

  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({ deliveryId: null, bankId: null, isActive: 'true', accountType: 'Delivery', fromDate: today, toDate: today, isOrder: false, wareHouseId: defaultWareHouseId });

  const [showAddModal, setShowAddModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);
  const [selectedBank, setSelectedBank] = useState(false);


  const [transaction, setTransaction] = useState(false);


  const bankAccounts = accountData?.filter(account => account.type === 'Bank');

  const selectedWareHouse = wareHouseData.find(option => option.id == filter.wareHouseId);
  const selectedAccount = accountData.find(option => option.id == filter.accountId);

  // idRow Change
  useEffect(() => {
    const bankId = bankOptions.find(option => option.value === idRow?.bankId) || [];
    setSelectedBank(bankId);

    setTransaction(
      {
        date: idRow?.date || today,
        isOut: idRow?.isOut || false,
        isCredit: false,
        isBank: idRow?.isBank || false,
        total: idRow?.total || '',
        details: idRow?.details || '',
        accountId: idRow?.accountId || filter.accountId,
        deliveryId: idRow?.deliveryId || filter.deliveryId,
        createdBy: createdBy,
        type: idRow?.isOut ? 'Payment' : 'Receive' || 'Receive',
        wareHouseId: idRow?.wareHouseId || filter.wareHouseId,
      }
    )
  }, [idRow]);


  // isOut Change
  useEffect(() => {
    setTransaction((prev) => ({
      ...prev,
      type: transaction.isOut ? 'Payment' : 'Receive',
    }));
  }, [transaction.isOut]);



  const resetForm = () => {
    setIdRow([]);
    setTransaction({
      createdBy: createdBy,
      type: 'Payment',
      details: "",
      isOut: false,
      deliveryId: filter.deliveryId,
    })
  }


  // Generic handler for input changes
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value)
    }
    setTransaction((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const addModal = () => {
    if ((filter.deliveryId || filter.deliveryId) && filter.wareHouseId) {
      setIdRow([]);
      resetForm();
      setShowAddModal(!showAddModal);
    } else {
      toast.error((filter.deliveryId || filter.deliveryId) ? 'Please Select WareHouse' : 'Please Select Account')
    }
  }

  const cartModal = (e) => {
    if (!showCartModal) {
      setIdRow(e);
    }
    setShowCartModal(!showCartModal);
  }

  const recoverModal = (e) => {
    if (!showRecoverModal) {
      setIdRow(e);
    }
    setShowRecoverModal(!showRecoverModal);
  }

  const trashModal = (e) => {
    if (!showTrashModal) {
      setIdRow(e);
    }
    setShowTrashModal(!showTrashModal);
  }

  const deleteModal = (e) => {
    if (!showDeleteModal) {
      setIdRow(e);
    }
    setShowDeleteModal(!showDeleteModal);
  }

  const editModal = (e) => {
    if (!showEditModal) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  // handle select change
  const handleSelectChange = (selectedOption, name) => {
    if (name == 'bankId') {
      setSelectedBank(selectedOption)
    }
    setTransaction((prev) => ({
      ...prev,
      [name]: selectedOption.value,
    }));
  };

  // Bank Options
  const bankOptions = bankAccounts?.map(item => ({
    value: item.id,
    label: `${item.name} [${item.phone}]`,
  }))
  bankOptions.push({ value: null, label: 'All Banks' })


  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }

  //Excel Data
  const getExcelData = (data, getSummary) => {

    const transformedData = [];
    data?.forEach(item => {

      const newItem = {
        id: item.id,
        createdAt: item.createdAt,
        account: item.account?.name,
        delivery: item.delivery?.name,
        total: item.isOut ? -item.total : item.total,
        discount: item.discount,
        type: item.type,
        details: item.details,
      };

      transformedData.push(newItem);
    });

    let summary;
    if (type == 'Customer') {
      summary = {
        TotalCash: getSummary?.totalCash,
        TotalCredit: getSummary?.totalCredit,
        Balance: getSummary?.totalCash - getSummary?.totalCredit,
      }
    } else if (type == 'Supplier') {
      summary = {
        TotalCash: getSummary?.totalCash,
        TotalCredit: -getSummary?.totalCredit,
        Balance: getSummary?.totalCash - getSummary?.totalCredit,
      }
    } else if (type == 'Employee' || type == 'Expenses') {
      summary = {
        Balance: getSummary?.totalCash - getSummary?.totalCredit,
      }
    }


    return { transformedData, summary };
  };


  let newColumnDefs = [
    { title: 'ID', dataIndex: 'id', width: '4%', sorter: (a, b) => a.age - b.age },
    { title: 'CreatedAt', dataIndex: 'createdAt', render: dateTableFormatter, width: '10%' },
    { title: 'Date', dataIndex: 'date', width: '8%' },
    { title: 'Account', dataIndex: 'account', render: (account) => (account?.name) },
    { title: devData.deliveryName, dataIndex: 'delivery', render: (delivery) => (delivery?.name) },
    { title: 'Total In', dataIndex: 'total', width: '8%', render: debitFormatter },
    { title: 'Total Out', dataIndex: 'total', width: '8%', render: creditFormatter },
    { title: 'Discount %', dataIndex: 'discount', width: '7%' },
    { title: 'Type', dataIndex: 'type', width: '10%', render: typeFormatter },
    { title: 'Details', dataIndex: 'details' },
    { title: 'cum Balance', dataIndex: 'cumulativeBalance', render: numberFormatter },
  ];

  useEffect(() => {

    if (filter.isActive == 'true') {
      newColumnDefs.push(
        {
          title: 'View',
          width: '5%',
          render: (_, record) => (
            <FcKindle size={18} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => cartModal(record)} />
          ),
        },
        {
          title: 'Edt',
          width: '5%',
          render: (_, record) => {
            if (record.type == 'Payment' || record.type == 'Receive') {
              return (<FiEdit size={16} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => editModal(record)} />);
            }
            return null;
          },
        },
        {
          title: 'Del',
          width: '5%',
          render: (_, record) => {
            if (record.type == 'Payment' || record.type == 'Receive') {
              return (<BiSolidTrash size={18} style={{ cursor: 'pointer', color: '#e6434f' }} onClick={() => deleteModal(record)} />);
            }
            return null;
          },
        }
      );
    } else {
      newColumnDefs.push({
        title: 'Rec',
        width: '3%',
        render: (_, record) => (
          <FcUndo size={20} style={{ cursor: 'pointer' }} onClick={() => recoverModal(record)} />
        ),
      });
    }
    setColumnDefs(newColumnDefs);

  }, [filter.isActive]);

  const formView = (
    <div style={{ userSelect: 'none' }}>



      {/* Date */}
      <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
          <Form.Label>Type</Form.Label>
          <select className="form-control" id='isOut' name='isOut'
            value={transaction.isOut}
            onChange={handleInputChange}
            required
          >
            <option value='false'>Receive</option>
            <option value='true'>Payment</option>
          </select>
        </Form.Group>


        <Form.Group as={Col}>
          <Form.Label className='formLabel'>Date<span style={{ color: 'red' }}> *</span></Form.Label>
          <input type="date" className="form-control" name='date'
            onChange={(e) => handleInputChange(e, 'text')}
            value={transaction.date}
            required
          ></input>
        </Form.Group>

      </Row>



      {/* on Bank  */}
      <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
          <Form.Label>By Bank</Form.Label>
          <select className="form-control" id='isBank' name='isBank'
            value={transaction.isBank}
            onChange={handleInputChange}
            required
          >
            <option value='false'>no</option>
            <option value='true'>yes</option>
          </select>
        </Form.Group>

        {transaction.isBank &&
          <Form.Group as={Col} >
            <Form.Label>By Bank</Form.Label>
            <Select
              options={bankOptions}
              isSearchable={true}
              placeholder="Select"
              value={selectedBank}
              onChange={(e) => handleSelectChange(e, 'bankId')}
              required
            />
          </Form.Group>
        }

      </Row>


      {/* Select bank */}
      <Row className="mb-3" style={{ marginTop: '2%' }}>


        <Form.Group as={Col}>
          <Form.Label>Total</Form.Label>
          <input type="text" name="total" id="total" className="form-control"
            value={transaction.total}
            onChange={handleInputChange}></input>
        </Form.Group>


      </Row>




      <Row className="mb-3" style={{ marginTop: '2%' }}>

        <Form.Group as={Col}>
          <Form.Label>Details</Form.Label>
          <textarea className="form-control" name="details" id="details"
            value={transaction.details}
            onChange={handleInputChange}></textarea>
        </Form.Group>

      </Row>


    </div>
  )

  const tableFooter = (

    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
      <div>
        <span className='table-footer footer-one'>TotalSales:   {numberFormatter(data?.summary?.totalSales, 2)} {settingData.currency}</span>
        <span className='table-footer footer-green'>Payments:   {numberFormatter(data?.summary?.totalCash, 2)} {settingData.currency}</span>
        <span className='table-footer footer-yellow'>Credit: {numberFormatter(data?.summary?.totalCredit, 2)} {settingData.currency}</span>
        <span className='table-footer footer-one'>Balance: {numberFormatter(data?.summary?.totalCash - data?.summary?.totalCredit, 2)} {settingData.currency}</span>
      </div>
    </div>

  );

  const modalFooter = (
    <div style={{ textAllign: 'center' }}>

      {/* Total */}
      <Row className="mb-3" style={{ marginTop: '2%', justifyContent: 'center' }}>
        <Form.Group as={Col} style={{ display: 'flex', justifyContent: 'center' }}>
          <Form.Label></Form.Label>

        </Form.Group>
      </Row>
    </div>
  )


  const report = (
    <ConfigProvider
      theme={{
        token: { colorPrimary: '#4a90e2' },
        components: {
          Card: {
            colorBgContainer: 'white',
            headerBg: '#39aaa5',
            headerFontSize: '20px',
            colorTextHeading: 'white',
          },
        },
      }}
    >
      <Card
        title={<div style={{ textAlign: 'center', fontSize: 40 }}>Statement Of Account</div>}
        bordered={true}
        style={{ width: '100%', alignSelf: 'center' }}
        headStyle={{ textAlign: 'center' }}
        ref={reportRef}
      >
        <div style={{ width: '80%', margin: '0 auto' }}>
          {/* Date */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Date:</h5>
            <h5>{filter.fromDate} to {filter.toDate}</h5>
          </div>

          {/* Warehouse */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{devData.wareHouseName}:</h5>
            <h5>{selectedWareHouse?.name || 'All ' + devData.wareHouseName}</h5>
          </div>

          {/* Account */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Account:</h5>
            <h5>{selectedAccount?.name || 'All Accounts'}</h5>
          </div>

          <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />

             
              <h5 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Sales</h5>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h5>Total Sales:</h5>
                  <h5>{numberFormatter(data?.summary?.totalSales, 2)}{settingData.currency}</h5>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h5>Total Sales Cash:</h5>
                  <h5>{numberFormatter(data?.summary?.totalSalesCash, 2)}{settingData.currency}</h5>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h5>Total Sales Credit:</h5>
                  <h5>{numberFormatter(data?.summary?.totalSalesCredit, 2)}{settingData.currency}</h5>
                </div>

                <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h5>Total Sales</h5>
                  <h5 style={{ color: data?.summary?.totalSales > 0 ? 'green' : 'red' }}>
                    {numberFormatter(data?.summary?.totalSales, 2)}{settingData.currency}
                  </h5>
                </div>
              </div>

          <Divider style={{ marginTop: '10%', marginBottom: '5px' }} />

          {/* Balance Section */}
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5 style={{ textDecoration: 'underline', fontWeight: 'bold', fontSize: '25px' }}>Balance:</h5>
                <h5 style={{ color: 'green', fontSize: '25px' }}>
                  {numberFormatter(data?.summary?.totalSales, 2)}{settingData.currency}
                </h5>
              </div>
            </div>
 

      


        </div>
      </Card>
    </ConfigProvider>
  );

  return (
    <div className='card'>

      <div>

        <SearchFilter setFilter={setFilter} />
        <DropDownFilter setFilter={setFilter} filterBy={'deliveryId'} placeHolder={'All ' + devData.deliveryName} filter={filter} setLoadingCounter={() => setLoadingCounter((prevCounter) => prevCounter + 1)} />
        <DatePicker setFilter={setFilter} filter={filter} />
        <DropDownFilter setFilter={setFilter} filterBy={'wareHouseId'} placeHolder={'All ' + devData.wareHouseName} filter={filter} setLoadingCounter={() => setLoadingCounter((prevCounter) => prevCounter + 1)} />
        <IsOrderFilter setFilter={setFilter} />
        <AddButton addModal={addModal} />

      </div>

      {loadingCounter < 2 &&
        <Loader />
      }


      {loadingCounter == 2 &&
        <>

          <SimpleTable
            filter={filter}
            showAddModal={showAddModal}
            addModal={addModal}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            refreshTable={refreshTable}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
            selection={true}
            report={report}
            reportRef={reportRef}
          />

          <TrashModal
            showModal={showTrashModal}
            toggleModal={trashModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <DeleteModal
            showModal={showDeleteModal}
            toggleModal={deleteModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ transactionId: idRow.id, transaction }}
          />

          <CartModal
            showModal={showCartModal}
            toggleModal={cartModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <RecoverModal
            showModal={showRecoverModal}
            toggleModal={recoverModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <AddModal
            showModal={showAddModal}
            toggleModal={addModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'lg'}
            resetForm={resetForm}
            footer={modalFooter}
            api={api}
            data={{ transaction }}
          />

          <EditModal
            showModal={showEditModal}
            toggleModal={editModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'lg'}
            footer={modalFooter}
            api={api}
            data={{ transactionId: idRow.id, transaction }}
          />


        </>
      }

    </div>
  )
}

export default DeliveryTransaction;
