import React, { useState, useEffect, useRef, useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';

import { useNavigate } from 'react-router-dom';

import Discount from './crud/discount';
import TotalDiscount from './crud/totalDiscount';
import Net from './crud/net';
import TotalNet from './crud/totalNet';
import Quantity from './crud/quantity';

import Serial from './crud/serial';
import Variable from './crud/variable';
import Void from './crud/void';
import Detail from './crud/detail';

import PayNow from './payNow/payNow';
import { Switch, Input, Tag, Spin } from 'antd';

import delete_png from '../images/trash.png';
import './sales.css';
import Loader from '../components/loader/loader';

//images
import {
  FcViewDetails, FcRefresh, FcDeleteDatabase, FcCurrencyExchange,
  FcRedo, FcUndo, FcClock, FcPrint, FcInternal, FcDisclaimer, FcPlus, FcCancel
} from "react-icons/fc";

import { DataContext } from '../init/getData';
import { CartContext } from '../init/cartData';
import { handleBarcode } from './functions/handleBarcode';
import { handleSerial } from './functions/handleSerial';
import { numberFormatter } from '../dynamic/numberFormatter';
import { TbChevronsDownLeft } from 'react-icons/tb';


const NewSale = () => {


  const {
    api, categoryData,
    salableData, refSalableData,
    accountData, refAccountData,
    rateData, settingData, userData,
    devData, refDevData,
  } = useContext(DataContext);





  const {
    allCart, setCartAtIndex,
    total, setTotal, setTva,
    finalDiscountArray, setFinalDiscount,
    editModeIdArray, editModeArray,
    finalNetTotal, setFinalNetTotal,
    setReturnMode, returnModeArray,
    selectedTab, addNewTab, removeTab, editCartArray, openTab,
    tabArray, tvaArray, setIsPrint, isPrint, setWholeSale, wholeSaleArray,
    tab, setTab
  } = useContext(CartContext);


  const cart = allCart[selectedTab];
  const returnMode = returnModeArray[selectedTab];
  const isEditMode = editModeArray[selectedTab];
  const finalDiscount = finalDiscountArray[selectedTab];
  const isTva = tvaArray[selectedTab];
  const wholeSale = wholeSaleArray[selectedTab];

  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role;

  const [showproducts, setShowProducts] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedSerial, setSelectedSerial] = useState();

  const [showDiscountModal, setDiscountModal] = useState(false);
  const [showTotalDiscountModal, setTotalDiscountModal] = useState(false);
  const [showNetModal, setNetModal] = useState(false);
  const [showTotalNetModal, setTotalNetModal] = useState(false);

  const [showSerialModal, setSerialModal] = useState(false);
  const [showVariableModal, setVariableModal] = useState(false);
  const [showQuantityModal, setQuantityModal] = useState(false);
  const [showVoidModal, setVoidModal] = useState(false);
  const [showDetailModal, setDetailModal] = useState(false);

  const [showPayNowModal, setPayNowModal] = useState(false);

  const [product, setProduct] = useState({});

  const [selectedRow, setSelectedRow] = useState(null);

  const [barCodeSearch, setBarCodeSearch] = useState('');
  const [SerialSearch, setSerialSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [isCredit, setIsCredit] = useState(false);
  const [isOut, setIsOut] = useState(false);


  // get devSettings and products
  useEffect(() => {
    const fetchData = async () => {
      try {
        document.getElementById("isPrint").checked = isPrint;
      } catch (error) {
        console.error('Error Contact Support');
      }
    };
    fetchData();
  }, []);



  // load data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (!devData.isCloud) {
          await refAccountData();
          await refSalableData(wholeSale);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error refreshing data Contact Support');
      }
    };
    fetchData();
  }, []);

  //refresh product
  const refreshProducts = async () => {
    setIsLoading(true);
    await refSalableData(wholeSale);
    await refAccountData();
    toast.success('Refreshed Successfully')
    setShowProducts([]);
    setIsLoading(false);
  }

  useEffect(() => {
    updateCartTotal(cart);
  }, [selectedTab]);

  const wholeSaleChange = async (e) => {
    try {
      setIsLoading(true)
      setWholeSale(selectedTab)
      await refSalableData(!wholeSale);
      setCartAtIndex(selectedTab, []);
      setFinalDiscount(selectedTab, 0);
      updateCartTotal([]);
      setShowProducts([]);
      setIsLoading(false)
    } catch (error) {
      console.error('Error Getting Data');
    }
  }





  // set Final Net Total
  useEffect(() => {

    let finalWithTva = 0;

    for (const product of cart) {
      if (product.isTva) {
        finalWithTva += parseFloat(product.totalPrice + (product.totalPrice * settingData.tva / 100))
      } else {
        finalWithTva += parseFloat(product.totalPrice);
      }
    }

    const finalTotal = isTva ? finalWithTva - (finalWithTva * finalDiscount / 100) : total - (total * finalDiscount / 100);
    setFinalNetTotal(finalTotal);
  }, [isTva, total, finalDiscount]);



  const discountModal = (product) => {
    if (product) {
      setSelectedProduct(product.id);
      setSelectedSerial(product.serial);
    }
    setDiscountModal(!showDiscountModal);
  }

  const totalDiscountModal = (product) => {
    if (product) { setSelectedProduct(product.id); }
    setTotalDiscountModal(!showTotalDiscountModal);
  }

  const netModal = (product) => {
    if (product) {
      setSelectedProduct(product.id);
      setSelectedSerial(product.serial);
    }
    setNetModal(!showNetModal);
  }

  const totalNetModal = (product) => {
    if (product) { setSelectedProduct(product.id); }
    setTotalNetModal(!showTotalNetModal);
  }

  const serialModal = () => {
    setSerialModal(!showSerialModal);
  }

  const payNowModal = (type) => {
    if (cart.length > 0) {
      if (type == 'PayCash') {
        setIsCredit(false);
        setIsOut(false);
      } else if (type == 'PayCredit') {
        setIsCredit(true);
        setIsOut(false);
      } else if (type == 'ReturnCash') {
        setIsCredit(false);
        setIsOut(true);
      } else if (type == 'ReturnCredit') {
        setIsCredit(true);
        setIsOut(true);
      }

      setPayNowModal(!showPayNowModal);
    } else {
      toast.error('Cart is Empty')
    }
  }

  const variableModal = () => {
    setVariableModal(!showVariableModal);
  }

  const quantityModal = (product) => {
    if (product && product.type == 'serial') {
      toast.error('you have to add serial')
    } else {
      if (product) { setSelectedProduct(product.id); }
      setQuantityModal(!showQuantityModal);
    }
  }


  //set serrail
  const setSerial = (serial) => {
    if (serial) {
      product.serial = serial;

      // const isSerialExists = product.stocks.some(stock =>
      //   stock.serials.some(s => s.serial === serial)
      // );

      //const isSerialSold = product.stocks.some(stock =>
      //   stock.serials.some(s => s.serial === serial && s.isSold === true)
      // );

      //if (!isSerialExists && product.isStock) {
      //  toast.error('This Serial Doesnt exist')
      // } else
      //  if (!isSerialSold && !returnMode) {
      //    addToCart(product, 1);
      //  } else
      //   if (isSerialSold && !returnMode) {
      //      toast.error('This Serial is already sold')
      //   } else
      //     if (!isSerialSold && returnMode) {
      //       toast.error('This Serial is not sold')
      //     } else
      //      if (isSerialSold && returnMode) {
      //        addToCart(product, 1);
      //      }
      //setSerial(null);
      addToCart(product, 1);
    }
  }

  //set serrail
  const setVariant = (variant) => {
    if (variant) {
      addToCart(variant, 1);
      setVariant(null);
    }
  }



  // handle Add ToCart
  const addProductToCart = async (productAdd, quantity) => {

    let product = {
      type: productAdd.type,
      isStock: productAdd.isStock,
      category: productAdd.category.name,
      id: productAdd.id,
      productId: productAdd.id,
      name: productAdd.name,
      type: productAdd.type,
      details: productAdd.details,
      barcode: productAdd.barcode,
      quantity: 1,
      price: productAdd.price,
      discount: productAdd.discount,
      netPrice: productAdd.price,
      totalPrice: productAdd.price,

      cost: productAdd.cost,
      totalStock: productAdd.totalStock,
      serial: productAdd.serial,
      isStock: productAdd.isStock,
      compoundArray: productAdd.compoundArray,
    }

    const updateTab = [...tab][selectedTab];
    const updateCart = [...updateTab.cart];

    //check if product exists
    const productIndex = updateCart.findIndex(item => item.id === product.id);

    if (productIndex !== -1) {
      // If the product exists, update its quantity
      const existingProduct = { ...updateCart[productIndex] };
      existingProduct.quantity += product.quantity;
      existingProduct.totalPrice = existingProduct.quantity * existingProduct.price; // Update totalPrice accordingly
      updateCart[productIndex] = existingProduct;
    } else {
      // If the product doesn't exist, add it to the cart
      updateCart.push(product);
    }

    updateTab.cart = updateCart;

  };


  // handle Add ToCart
  const handleAddToCart = async (product, quantity, serial) => {
    if (product.type == 'serialized') {
      if (!serial) {
        setProduct(product);
        serialModal();
      } else {
        product.serial = serial;
        addToCart(product, quantity)
      }
    }
    else if (product.type == 'variable') {
      setProduct(product);
      variableModal();
    }
    else if (product.type == 'simple' || product.type == 'production' || product.type == 'compound') {
      addToCart(product, quantity)
    }
  };




  //Add to Cart
  const addToCart = async (productAdd, quantity) => {


    let product = {
      type: productAdd.type,
      isStock: productAdd.isStock,
      isTva: productAdd.isTva,
      category: productAdd.category.name,
      id: productAdd.id,
      productId: productAdd.id,
      name: productAdd.name,
      type: productAdd.type,
      details: productAdd.details,
      barcode: productAdd.barcode,

      price: productAdd.price,
      discount: productAdd.discount,
      netPrice: productAdd.price,
      quantity: productAdd.quantity,
      totalPrice: productAdd.price,

      cost: productAdd.cost,
      totalStock: productAdd.totalStock,
      serial: productAdd.serial,
      compoundArray: productAdd.compoundArray,
    }




    const index = cart.findIndex((item) => item.id === product.id);
    const isSerialInCart = cart.some((item) => item.serial === product.serial);

    if (product.type == 'serialized' && isSerialInCart) {
      toast.error('Serial already in the cart');
      setSerialSearch('');
      return;
    }

    if (index >= 0 && product.type !== 'serialized') {
      const updatedCart = [...cart];
      const item = updatedCart[index];

      // get quantity in all carts
      let stockAvailable = 0;
      for (const myCart of allCart) {
        for (const myProduct of myCart) {
          if (myProduct.id == product.id) {
            stockAvailable += myProduct.quantity;
          }
        }
      }

      // check if product isStock and if quantity > cart

      item.quantity += quantity;
      updateProductTotal(product.id, updatedCart)
      updateCartTotal(updatedCart);

    } else {
      const updatedCart = [...cart, { ...product, quantity: quantity, discount: 0 }];
      setCartAtIndex(selectedTab, updatedCart);
      updateProductTotal(product.id, updatedCart)
      updateCartTotal(updatedCart);
    }

  };




  // Remove from Cart
  const handleRemoveFromCart = (product) => {
    if (selectedRow) {
      const index = cart.findIndex((item) => item.id === product.id);

      if (index >= 0) {
        const updatedCart = [...cart];

        const quantity = updatedCart[index].quantity;
        if (quantity > 1) {
          updatedCart[index].quantity -= 1;
          updateProductTotal(product.id, updatedCart)
        } else {
          updatedCart.splice(index, 1);
          setCartAtIndex(selectedTab, updatedCart);
          setSelectedRow(null);
        }
        updateCartTotal(updatedCart);
      } else {
        setSelectedRow(null);
        toast.error('Please Select a Product')
      }
    } else {
      toast.error('Please Select a Product')
    }
  };

  //set Discount Table
  const setDiscount = (value) => {
    let index = cart.findIndex((item) => item.id === selectedProduct);

    const product = salableData.find((item) => item.id === selectedProduct);

    if (product.type == 'serialized') {
      index = cart.findIndex((item) => item.serial === selectedSerial);
    }

    if (index >= 0) {
      const updatedCart = [...cart];
      const price = updatedCart[index].price;
      updatedCart[index].discount = value;
      updatedCart[index].netPrice = price - (price * value / 100);

      const selectedProductCost = cart[index].cost;
      const selectedProductPrice = cart[index].netPrice;

      if (selectedProductPrice <= selectedProductCost) {
        toast.error('you are selling underCost')
      }

      updateProductTotal(selectedProduct, updatedCart)
      updateCartTotal(updatedCart);
    }
  }

  // set Net Table
  const setNet = (value) => {
    value = settingData.isRated ? value / settingData.rate : value;
    let index = cart.findIndex((item) => item.id === selectedProduct);

    const product = salableData.find((item) => item.id === selectedProduct);

    if (product.type == 'serialized') {
      index = cart.findIndex((item) => item.serial === selectedSerial);
    }

    const selectedProductCost = cart[index].cost;

    if (value <= selectedProductCost) {
      toast.error('you are selling underCost')
    }

    if (index >= 0) {
      const selectedProductPrice = cart[index].price;
      const discountPercentage = ((selectedProductPrice - value) / selectedProductPrice) * 100;
      const updatedCart = [...cart];
      updatedCart[index].discount = discountPercentage;
      updatedCart[index].netPrice = value;
      updateProductTotal(selectedProduct, updatedCart)
      updateCartTotal(updatedCart);

    }
  }




  // set Quantity
  const setQuantity = (value) => {
    const index = cart.findIndex((item) => item.id === selectedProduct);
    const product = salableData.find(item => item.id === selectedProduct);

    let checkValue = value;

    if (isEditMode) {
      checkValue -= parseFloat(editCartArray[selectedTab][index].quantity);
    }

    // get quantity in all carts
    let otherQuantity = 0;
    for (let i = 0; i < allCart.length; i++) {
      if (i !== selectedTab) {
        const myCart = allCart[i];
        for (const myProduct of myCart) {
          if (myProduct.id == product.id) {
            otherQuantity += myProduct.quantity;
          }
        }

      }
    }

    const totalQuantity = parseFloat(otherQuantity) + value;

    if (index >= 0) {
      const updatedCart = [...cart];

      updatedCart[index].quantity = value;
      updateProductTotal(selectedProduct, updatedCart)
      updateCartTotal(updatedCart);
      return true;

    }

  }


  // update Product Total
  function updateProductTotal(product, cart) {
    const index = cart.findIndex((item) => item.id === product);
    if (index >= 0) {
      const updatedCart = [...cart];
      const quantity = parseFloat(updatedCart[index].quantity);
      const netPrice = parseFloat(updatedCart[index].netPrice);
      if (settingData.isRated) {
        updatedCart[index].totalPrice = quantity * netPrice;
      } else {
        updatedCart[index].totalPrice = quantity * netPrice;
      }
      setCartAtIndex(selectedTab, updatedCart);
      updateCartTotal(updatedCart);
    }

  }


  // change Final Net Total
  const changeFinalNetTotal = (value) => {
    let getDiscountPercentage = ((total - value) / total) * 100;
    setFinalDiscount(selectedTab, getDiscountPercentage);
  }


  // set FinalDiscount
  const changeFinalDiscount = (value) => {
    setFinalDiscount(selectedTab, value);
  }




  // update Cart Total
  function updateCartTotal(items) {
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      total += parseFloat(item.totalPrice);
    }
    setTotal(total);
  }

  //Close Payment
  const closePaymentDone = () => {
    setCartAtIndex(selectedTab, []);
    setFinalDiscount(selectedTab, 0);
    updateCartTotal([]);
    if (selectedTab != 0) {
      removeTab(selectedTab);
    }
  }

  //return Mode Switch 
  const returnModeSwitch = () => {
    closePaymentDone();
    setReturnMode(selectedTab);
  }



  const selectCategory = (catId) => {
    const filteredProducts = salableData.filter(item => item.category.id === catId);
    setShowProducts(filteredProducts);
  }



  // Add Details
  const addDetails = (selectedRow, details) => {

    if (selectedRow) {
      const index = cart.findIndex((item) => item.id === selectedRow.id);

      if (index >= 0) {
        const updatedCart = [...cart];
        updatedCart[index].details = details;

        setCartAtIndex(selectedTab, updatedCart);
      } else {
        setSelectedRow(null);
        toast.error('Please Select a Product')
      }
    } else {
      toast.error('Please Select a Product')
    }
  }


  // Print Check box
  const printBox = () => {
    setIsPrint(!isPrint);
    var isPrintCheckbox = document.getElementById("isPrint");
    let isPrintStatus = isPrintCheckbox.checked;

    document.getElementById("isPrint").checked = !isPrintStatus;

  }





  return (


    <div className="card" style={{ height: '99vh', userSelect: 'none' }}>

      <Spin size="large" spinning={isLoading} fullscreen />





      {/* Modals */}

      <Discount showModal={showDiscountModal} toggleModal={discountModal} setDiscount={setDiscount} />
      <TotalDiscount showModal={showTotalDiscountModal} toggleModal={totalDiscountModal} changeFinalDiscount={changeFinalDiscount} />
      <Net showModal={showNetModal} toggleModal={netModal} setNet={setNet} />
      <TotalNet showModal={showTotalNetModal} toggleModal={totalNetModal} changeFinalNetTotal={changeFinalNetTotal} />
      <Quantity showModal={showQuantityModal} toggleModal={quantityModal} setQuantity={setQuantity} />

      <Serial showModal={showSerialModal} toggleModal={serialModal} setSerial={setSerial} />
      <Variable showModal={showVariableModal} toggleModal={variableModal} setVariant={setVariant} product={product} userData={userData} settingData={settingData} />
      <Void showModal={showVoidModal} toggleModal={() => setVoidModal(!showVoidModal)} closePaymentDone={closePaymentDone} />
      <Detail showModal={showDetailModal} toggleModal={() => setDetailModal(!showDetailModal)} addDetails={addDetails} selectedRow={selectedRow} />



      <PayNow
        showModal={showPayNowModal}
        toggleModal={payNowModal}
        cart={cart}
        isPrint={isPrint}
        userData={userData}
        settingData={settingData}
        finalDiscount={finalDiscount}
        finalNetTotal={finalNetTotal}
        closePaymentDone={closePaymentDone}
        isTva={isTva}
        removeTab={removeTab}
        selectedTab={selectedTab}
        isCredit={isCredit}
        isOut={isOut}
      />







      {/* Categories */}

      <div style={{ display: 'flex', height: '99vh' }}>

        <div className="card" style={{ display: 'flex', height: 'calc(99vh - 20px)', width: '12%', overflowY: 'auto', maxHeight: 'calc(99vh - 20px)' }}>
          {categoryData.map((category) => (
            <div key={category.id}>
              <span className="subCat" onClick={() => selectCategory(category.id)}>
                {category.name}
              </span>
              <div>
              </div>
            </div>
          ))}
        </div>



        {/* Products */}

        <div className="card" style={{ height: 'calc(99vh - 20px)', width: settingData.serializedProduct ? '40%' : '50%', overflowY: 'auto' }}>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {showproducts.map((product, index) => (
              (!product.isStock || (product.isStock) || returnMode) && (
                <div className='card' key={product.id} style={{ justifyContent: 'center', textAlign: 'center', width: settingData.serializedProduct ? '31%' : '23%', height: '100px', cursor: 'pointer', marginLeft: 0, marginBottom: '10px', backgroundColor: settingData.useProductColor ? product.color : '#b6e3f4' }} onClick={() => handleAddToCart(product, 1)}>
                  <div style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center' }}>
                    {product.name.length <= 25 ? (product.name) : (`${product.name.substring(0, 25)}...`)}</div>
                  {/* 
                  <div style={{ fontSize: '13px', textAlign: 'center' }}>{product.isStock ? `Stock: ${product.totalStock}` : null}</div>
                  */}
                  <div style={{ fontSize: '18px', textAlign: 'center', userSelect: 'none', color: 'green', fontFamily: 'sans-serif' }}>{product.type === 'variable' ? "options" : numberFormatter(product.price, 2).toLocaleString() + ' ' + settingData.currency}</div>
                  {settingData.isRated &&
                    <div style={{ fontSize: '15px', textAlign: 'center', userSelect: 'none', color: 'green', fontFamily: 'sans-serif' }}>{product.type === 'variable' ? "options" : numberFormatter((product.price * settingData.rate), 2).toLocaleString() + ' ' + settingData.ratedCurrency}</div>
                  }
                </div>
              )

            ))}
          </div>
        </div>








        <div className="card" style={{ height: 'calc(99vh - 20px)', width: settingData.serializedProduct ? '50%' : '45%', padding: 10, display: 'flex', flexDirection: 'column' }}>

          {/* Sales Tabs # */}
          {tabArray.length > 1 &&
            <div className='card' style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>

              <div className="btn-group btn-group-toggle" data-toggle="buttons">

                {tabArray.map((tab, index) => (
                  <div key={index} >
                    <button style={{ marginRight: '10px' }} onClick={() => openTab(index)}
                      className={`btn btn-sm ${selectedTab === index ? 'btn-success' : 'btn-light'}`}>

                      {index == 0 ? 'Main Tab' : editModeArray[index] ? 'Editing Order ' + editModeIdArray[index] : returnModeArray[index] ? 'Return Tab ' + (index + 1) : 'Sales Tab ' + (index + 1)}
                    </button>
                  </div>
                ))}

              </div>

            </div>
          }


          {/* BarCode Serial # */}
          <div style={{ height: '50px', width: '100%' }}>

            <input type="search" onChange={(e) => setBarCodeSearch(e.target.value)} value={barCodeSearch}
              onKeyDown={(e) => handleBarcode(e, salableData, setShowProducts, barCodeSearch, setBarCodeSearch, handleAddToCart)}
              className="form-control searchItem" style={{ width: '40%' }} placeholder="BarCode Name" autoFocus />

            {/*
            {settingData.serializedProduct &&
              <input type="search" onChange={(e) => setSerialSearch(e.target.value)} value={SerialSearch}
                onKeyDown={(e) => handleSerial(e, salableData, SerialSearch, setSerialSearch, handleAddToCart, returnMode)}
                className="form-control searchItem" placeholder="Serial #" />
            }
            */}

            {/* Add Remove Tab */}

            <div style={{ float: 'right' }}>
              <FcPlus size={30} onClick={addNewTab} style={{ cursor: 'pointer', marginLeft: '5' }} title="New Sale" />
            </div>

            {selectedTab != 0 &&
              <div style={{ float: 'right' }}>
                <FcCancel size={32} onClick={() => removeTab(selectedTab)} style={{ cursor: 'pointer', marginLeft: '5' }} title="Remove Sale" />
              </div>
            }

          </div>


          {/* Action Bar */}
          <div className='card' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FcRefresh size={30} style={{ cursor: 'pointer', margin: '0 10px' }} title="Refresh Products" onClick={refreshProducts} />

              {/* Print CheckBox */}
              <span>
                <FcPrint size={30} onClick={printBox} style={{ cursor: 'pointer', marginLeft: '50px' }} title="Empty All Cart" />
              </span>
              <input type="checkbox" id="isPrint" name="isPrint" disabled></input>

              {/* Add any other components specific to the left side */}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>


              {/*wholeSale */}
              {userRole.wholeSale &&
                <a style={{ fontWeight: 'bold', fontSize: '18px', cursor: 'pointer', margin: '0 15px', color: wholeSale ? 'green' : 'red', }} onClick={() => wholeSaleChange()}>wholeSale</a>
              }

              {/*Tva */}
              <a style={{ fontWeight: 'bold', fontSize: '18px', cursor: 'pointer', margin: '0 15px', color: isTva ? 'green' : 'red', }} onClick={() => setTva(selectedTab)}>TVA</a>

              {/*Return */}
              {!returnMode && !isEditMode &&
                <FcRedo size={33} style={{ cursor: 'pointer' }} onClick={returnModeSwitch} />
              }
              {returnMode && !isEditMode &&
                <FcUndo size={33} style={{ cursor: 'pointer' }} onClick={returnModeSwitch} />
              }
              <FcDeleteDatabase size={30} onClick={() => setVoidModal(!showVoidModal)} style={{ cursor: 'pointer', margin: '0 10px' }} title="Empty All Cart" />
              <FcViewDetails size={30} onClick={() => setDetailModal(!showDetailModal)} style={{ cursor: 'pointer', margin: '0 10px' }} title="Add Details" />
              <input type="image" src={delete_png} onClick={() => handleRemoveFromCart(selectedRow)} width={30} style={{ cursor: 'pointer', margin: '0 10px' }} title="Remove Product From Cart" />
              {/* Add any other components specific to the right side */}
            </div>
          </div>


          {/* Table */}
          <div style={{ overflowY: 'auto', flex: 1 }}>
            <table className="table">
              <thead>
                <tr>
                  {/*
                  {settingData.serializedProduct &&
                    <th style={{ width: '12%', paddingBottom: '15px', cursor: 'default' }}>Category</th>
                  }
                    */}

                  <th style={{ width: '20%', paddingBottom: '15px', cursor: 'default' }}>Item</th>
                  {settingData.serializedProduct &&
                    <th style={{ width: '20%', paddingBottom: '15px', cursor: 'default' }}>Serial #</th>
                  }
                  <th style={{ width: '13%', textAlign: 'center', paddingBottom: '15px', cursor: 'default' }}>Price</th>
                  <th style={{ width: '6%', textAlign: 'center', paddingBottom: '15px', cursor: 'default' }}>Dis</th>
                  <th style={{ width: '13%', textAlign: 'center', paddingBottom: '15px', cursor: 'default' }}>Net</th>
                  <th style={{ width: '13%', textAlign: 'center', paddingBottom: '15px', cursor: 'default' }}>Qnty</th>
                  <th style={{ width: '13%', textAlign: 'center', paddingBottom: '15px', cursor: 'default' }}>Total</th>
                </tr>
              </thead>
              <tbody>

                {cart && cart.map((product) => (
                  <>
                    <tr key={product.id} onClick={() => setSelectedRow(product)} style={{ backgroundColor: selectedRow === product ? '#a7e1eb' : 'white' }}>

                      {/*
                      {settingData.serializedProduct &&
                        <td>{product.category}</td>
                      }
                      */}

                      <td style={{ width: '10%', whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {product.name}
                      </td>

                      {/* Serial */}
                      {settingData.serializedProduct &&
                        <td>{product.serial}</td>
                      }

                      {/* Price */}
                      <td style={{ textAlign: 'center', paddingBottom: '10px' }}>{settingData.isRated ? numberFormatter(product.price * settingData.rate, 2) : numberFormatter(product.price, 2)}</td>

                      {/* Discount */}
                      <td style={{ textAlign: 'center', paddingBottom: '10px' }}>
                        <a style={{ cursor: 'pointer' }} onClick={() => discountModal(product)}>
                          {numberFormatter(parseFloat(product.discount), 2)}%
                        </a>
                      </td>

                      {/* Net Price */}
                      <td style={{ textAlign: 'center', paddingBottom: '10px' }} >
                        <a style={{ cursor: 'pointer' }} onClick={() => netModal(product)}>
                          {settingData.isRated ? numberFormatter(parseFloat(product.netPrice * settingData.rate), 2)  : numberFormatter(parseFloat(product.netPrice), 2)}
                        </a>
                      </td>

                      {/* Quantity */}
                      <td style={{ textAlign: 'center', paddingBottom: '10px' }}>
                        <a style={{ cursor: 'pointer' }} onClick={product.type === 'serialized' ? null : () => quantityModal(product)}>
                          x{numberFormatter(product.quantity, 3)}
                        </a>
                      </td>


                      {/* Total Price */}
                      {!settingData.isRated &&
                        <td style={{ textAlign: 'center', paddingBottom: '10px', color: 'green', fontFamily: 'sans-serif', fontWeight: 'bold' }}> {/*{((product.price * product.quantity) - (product.price * product.quantity * (product.discount/100))).toFixed(1)} */}
                          {numberFormatter(parseFloat(product.totalPrice), 2)}{settingData.currency}
                        </td>
                      }
                      {settingData.isRated &&
                        <td style={{ textAlign: 'center', paddingBottom: '10px', color: 'green', fontFamily: 'sans-serif', fontWeight: 'bold' }}> {/*{((product.price * product.quantity) - (product.price * product.quantity * (product.discount/100))).toFixed(1)} */}
                          {numberFormatter(parseFloat(product.totalPrice) * settingData.rate, 2)}
                        </td>
                      }

                    </tr>
                    {product.details && (
                      <tr key={product.id + 99}>
                        <td></td>
                        <td style={{ fontSize: '13px', color: 'orange' }}>Details</td>
                        <td colSpan="6" style={{ fontSize: '13px', color: 'orange' }}>{product.details}</td>
                      </tr>
                    )}
                  </>
                ))}

              </tbody>
            </table>
          </div>


          <div style={{ marginTop: '2%', borderTop: '2px solid #dfdfdf', marginRight: '2%', marginLeft: '2%' }}>


            {/* Total Price */}
            <div style={{ marginTop: 5 }}>
              <p style={{ fontWeight: 'bold', display: 'inline-block', margin: 0 }}>Total Price:</p>
              {settingData.isRated &&
                <p style={{ marginLeft: 5, float: 'right', margin: 0 }}>&nbsp;|| {numberFormatter(total * settingData.rate, 2)} {settingData.ratedCurrency}</p>
              }
              <p style={{ marginLeft: 5, float: 'right', margin: 0 }}>{numberFormatter(total, 2)} {settingData.currency}</p>
              <br />

              {/* TVA */}
              {isTva && (
                <>
                  <p style={{ fontWeight: 'bold', display: 'inline-block', color: 'red', margin: 0 }}>TVA:  </p>
                  <p style={{ display: 'inline-block', marginLeft: 5, float: 'right', margin: 0 }}>  {settingData.tva}%</p>
                  <br />
                </>
              )}

              {/* Discount */}
              <p style={{ fontWeight: 'bold', display: 'inline-block', margin: 0 }}>Discount: </p>
              <p style={{ marginLeft: 5, cursor: 'pointer', display: 'inline-block', float: 'right', margin: 0 }} onClick={totalDiscountModal}>{numberFormatter(parseFloat(finalDiscount), 2)}%</p>
              <br />



              {/* Final Price */}
              <p style={{ fontWeight: 'bold', display: 'inline-block' }}>Final Price:</p>

              {settingData.isRated &&
                <p style={{ marginLeft: 5, cursor: 'pointer', display: 'inline-block', color: 'green', fontWeight: 'bold', float: 'right', fontSize: '20px' }} onClick={totalNetModal}>
                  &nbsp; || &nbsp; &nbsp; {numberFormatter((finalNetTotal * settingData.rate), 2)} {settingData.ratedCurrency}</p>
              }
              <p style={{ marginLeft: 5, cursor: 'pointer', display: 'inline-block', color: 'green', fontWeight: 'bold', float: 'right', fontSize: '20px' }} onClick={totalNetModal}>
                {numberFormatter(finalNetTotal, 2)} {settingData.currency}</p>
            </div>

          </div>


          <div className="card" style={{ marginTop: 10, padding: 10, textAlign: 'center' }}>

            <div style={{ marginTop: 10 }}>

              {!returnMode && !isEditMode &&
                <>
                  <button type="button" style={{ height: '80px', width: settingData.serializedProduct ? '30%' : '40%' }} className="btn btn-success btn-lg " onClick={() => payNowModal('PayCash')}>
                    Pay Cash
                  </button>

                  {userRole.useCreditAction &&
                    <button type="button" style={{ height: '80px', width: settingData.serializedProduct ? '30%' : '40%', marginLeft: '5%' }} className="btn btn-danger btn-lg" onClick={() => payNowModal('PayCredit')}>
                      Pay Credit
                    </button>
                  }

                </>
              }

              {returnMode && !isEditMode &&
                <>
                  <button type="button" style={{ height: '80px', width: settingData.serializedProduct ? '30%' : '40%', color: 'yellow' }} className="btn btn-success btn-lg " onClick={() => payNowModal('ReturnCash')}>
                    Return Cash
                  </button>

                  {userRole.useCreditAction &&
                    <button type="button" style={{ height: '80px', width: settingData.serializedProduct ? '30%' : '40%', marginLeft: '5%', color: 'yellow' }} className="btn btn-danger btn-lg" onClick={() => payNowModal('ReturnCredit')}>
                      Return Credit
                    </button>
                  }

                </>
              }


            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSale;
