import React, { useState, useEffect, useContext } from 'react';


import AddModal from '../../../components/modals/addModal';
import EditModal from '../../../components/modals/editModal';

import Loader from '../../../components/loader/loader';
import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs, Image, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcViewDetails, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

import { DataContext } from '../../../init/getData';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


function SimpleProduct(props) {

  let { showAddModal, addModal, showEditModal, editModal, idRow, filter, refreshTableFunction } = props;


  const { userData, settingData, serverApi, api, refCategory, categoryData, refProductData, productData } = useContext(DataContext);
  const userRole = userData.role;
  const [data, setData] = useState([]);
  const createdBy = parseInt(userData.id);


  // Category Options
  const categoryOptions = categoryData.map(item => ({
    value: item.id,
    label: item.name,
  }));

  categoryOptions.push({ value: null, label: 'All Categories' })

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
    {
      uid: '-2',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    }
  ]);

  const [productUnits, setProductUnits] = useState([]);
  const [category, setCategory] = useState([]);
  const [simpleProduct, setSimpleProduct] = useState({});

  const product = simpleProduct;

  // idRow Change
  useEffect(() => {
    const categoryId = categoryOptions.find(option => option.value === idRow?.categoryId);
    setCategory(categoryId);
    setSimpleProduct(
      {
        createdBy: createdBy,
        type: idRow?.type || 'simple',
        name: idRow?.name,
        barcode: idRow?.barcode,
        categoryId: categoryId?.value || filter.categoryId,
        sku: idRow?.sku,
        isStock: idRow?.isStock,
        isSale: idRow?.isSale,
        isTva: idRow?.isTva,
        cost: idRow?.cost || 0,
        price: idRow?.price || 0,
        wholeSalePrice: idRow.wholeSalePrice || 0,
        productUnits: idRow?.productUnits,
        color: idRow?.color || "#b6e3f4",
        details: idRow?.details,
        unit: idRow?.unit || 'Pcs',
      }
    )
    setProductUnits(idRow?.units || [])

  }, [idRow]);

  const table = 'product';
  const title = 'Simple Product';



  // Add VarientRow
  const handleAddRow = () => {
    setProductUnits([...productUnits, { unit: '', value: '', productId: idRow?.id }]);
  };

  // Delete VarientRow
  const handleDeleteRow = (event) => {
    event.preventDefault()
    if (productUnits.length > 0) {
      const updatedRows = productUnits.slice(0, -1);
      setProductUnits(updatedRows);
    } else {
      toast.error('you need at least one variant')
    }
  };

  // Change Unit Array
  const handleUnitChange = (index, field, value) => {
    const updatedUnits = [...productUnits];
    const parsedValue = field === 'value' ? parseFloat(value) : value;
    updatedUnits[index] = { ...updatedUnits[index], [field]: parsedValue };
    setProductUnits(updatedUnits);
  }



  const resetForm = () => {
    setSimpleProduct({ createdBy: createdBy, type: 'simple' })
    setCategory('')
    setProductUnits([])
  }


  // Generic handler for input changes
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value)
    }
    setSimpleProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption, name) => {
    setCategory(selectedOption)
    setSimpleProduct((prevProduct) => ({
      ...prevProduct,
      [name]: selectedOption.value,
    }));
  };



  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => {
 
    setFileList(newFileList);


    if(newFileList.length < fileList.length){
    }
  }

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );


  const formGeneral = {
    label: `General`,
    key: 1,
    children:

      <>

        {/* BarCode  */}
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className='formLabel'><IoBarcodeOutline size={25} /> Barcode </Form.Label>
            <Form.Control type="text" name='barcode' placeholder="optional"
              value={simpleProduct?.barcode || ''}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Name  */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>Name<span style={{ color: 'red' }}> *</span></Form.Label>
            <Form.Control type="text" name='name'
              value={simpleProduct?.name || ''}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          {/* Sku  */}
          {(filter.categoryId && !idRow.categoryId) &&
            <Form.Group as={Col}>
              <Form.Label className='formLabel'>SKU</Form.Label>
              <Form.Control type="text" name="sku"
                value={simpleProduct?.sku || ''}
                onChange={handleInputChange} />
            </Form.Group>
          }

        </Row>

        <Row className="mb-3" style={{ marginTop: '4%' }}>

          {/* Category  */}
          {(!filter.categoryId || idRow.categoryId) &&
            <Form.Group as={Col}>
              <Form.Label className='formLabel'>Category<span style={{ color: 'red' }}> *</span></Form.Label>
              <Select
                style={{ width: '100px' }}
                options={categoryOptions.filter(option => option.value !== null)}
                isSearchable={true}
                placeholder="Select"
                value={category}
                onChange={e => handleSelectChange(e, 'categoryId')}
                required
              />
            </Form.Group>
          }


          {/* Sku  */}
          {(!filter.categoryId || idRow.categoryId) &&
            <Form.Group as={Col}>
              <Form.Label className='formLabel'>SKU</Form.Label>
              <Form.Control type="text" name="sku"
                value={simpleProduct?.sku || ''}
                onChange={handleInputChange} />
            </Form.Group>
          }


        </Row>

        <Row className="mb-3" style={{ marginTop: '2%' }}>

          {/* Sale */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>Salable<span style={{ color: 'red' }}> *</span></Form.Label>
            <select className="form-control" name='isSale'
              value={simpleProduct?.isSale}
              onChange={handleInputChange}>
              <option value='true'>yes</option>
              <option value='false'>no</option>
            </select>
          </Form.Group>


          {/* Stockable */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>Stockable<span style={{ color: 'red' }}> *</span></Form.Label>
            <select className="form-control" name='isStock'
              value={simpleProduct?.isStock}
              onChange={handleInputChange}>
              <option value='true'>yes</option>
              <option value='false'>no</option>
            </select>
          </Form.Group>
        </Row>

        <Row className="mb-3" style={{ marginTop: '4%' }}>

          {/* Cost */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>Cost<span style={{ color: 'red' }}> *</span></Form.Label>
            <Form.Control type="number" step="any" name='cost'
              value={simpleProduct?.cost}
              onChange={handleInputChange}
              required />
          </Form.Group>

          {/* WholeSalePrice */}
          {userRole.wholeSale &&
            <Form.Group as={Col} >
              <Form.Label className='formLabel'>WholeSale Price</Form.Label>
              <Form.Control type="number" name="wholeSalePrice"
                value={simpleProduct?.wholeSalePrice}
                onChange={handleInputChange}
                required />
            </Form.Group>
          }


          {/* Price */}
          <Form.Group as={Col} >
            <Form.Label className='formLabel'>Price<span style={{ color: 'red' }}> *</span></Form.Label>
            <Form.Control type="number" name="price"
              value={simpleProduct?.price}
              onChange={handleInputChange} 
              required />
          </Form.Group>
        </Row>

        <Row className="mb-3" style={{ marginTop: '2%' }}>

          {/* Details */}
          <Form.Group as={Col} sm={6}>
            <Form.Label className='formLabel'>Description</Form.Label>
            <Form.Control as="textarea" name="details"
              value={simpleProduct?.details || ''}
              onChange={handleInputChange} />
          </Form.Group>

          {/* isTva */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>TVA<span style={{ color: 'red' }}> *</span></Form.Label>
            <select className="form-control" name='isTva'
              value={simpleProduct?.isTva}
              onChange={handleInputChange}>
              <option value='true'>yes</option>
              <option value='false'>no</option>
            </select>
          </Form.Group>


          <Form.Group as={Col}>
            <Form.Label className='formLabel' htmlFor="exampleColorInput">Box Color</Form.Label>
            <Form.Control
              type="color"
              name="color"
              title="Choose your color"
              value={simpleProduct.color}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Row>
      </>


  };

  const secondaryUnit = {
    label: `Units`,
    key: 2,
    children: (
      <>
        <Row className="mb-3">

          {/* Product Unit  */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>Product Unit</Form.Label>
            <Form.Control type="text" name="unit"
              value={simpleProduct?.unit}
              onChange={handleInputChange} />
          </Form.Group>

        </Row>

        {productUnits.map((row, index) => (
          <div key={index}>
            {/* Render the labels only for the first row */}
            {index === 0 && (
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='formLabel'>Unit</Form.Label>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='formLabel'>Value</Form.Label>
                </Form.Group>
              </Row>
            )}

            <Row className="mb-3" style={{ marginTop: '-20px' }}>

              <Form.Group as={Col}>
                <Form.Control type="text" name="unit"
                  onChange={(e) => handleUnitChange(index, 'unit', e.target.value)}
                  value={row.unit} />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Control type="number" step="any" name="value"
                  onChange={(e) => handleUnitChange(index, 'value', e.target.value)}
                  value={row.value} />
              </Form.Group>



            </Row>

            <Row key={index + 100} className="mb-3">

            </Row>
          </div>
        ))}

        <Form.Group
          as={Col}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <FcPlus size={26} style={{ cursor: 'pointer' }} onClick={handleAddRow} />
          <HiMinusCircle size={28} style={{ cursor: 'pointer' }} onClick={(e) => handleDeleteRow(e)} />
        </Form.Group>
      </>
    )
  }

  const formImages = {
    label: `Images`,
    key: 3,
    children:

      <>

        {/* Main Image  */}
        <Row className="mb-3">



          <Form.Group>
            <Form.Label className='formLabel'><IoBarcodeOutline size={25} /> Image </Form.Label>

            <Upload
              action=""
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            {previewImage && (
              <Image
                wrapperStyle={{
                  display: 'none',
                }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
              />
            )}


          </Form.Group>


        </Row>
      </>


  }


  const items = [formGeneral];

  if(settingData.useSecondaryUnit){
    items.push(secondaryUnit)
  }

  if(settingData.useImage){
    items.push(formImages)
  }

  const formView = (
    <div style={{ userSelect: 'none' }}>

      <Tabs
        defaultActiveKey="1"
        centered
        items={items}
      />


    </div >);


  const modalFooter = (
    <div>

    </div>
  );



  return (
    <>



      <AddModal
        showModal={showAddModal}
        toggleModal={() => addModal('simple')}
        formView={formView}
        idRow={idRow}
        table={table}
        title={title}
        refreshTable={refreshTableFunction}
        size={'lg'}
        resetForm={resetForm}
        footer={modalFooter}
        api={api}
        data={{ product, productUnits, fileList }}
      />

      <EditModal
        showModal={showEditModal}
        toggleModal={() => editModal('simple', null)}
        formView={formView}
        idRow={idRow}
        table={table}
        title={title}
        refreshTable={refreshTableFunction}
        size={'lg'}
        footer={modalFooter}
        api={api}
        data={{ id: idRow?.id, product, productUnits }}
      />

    </>
  )
}

export default SimpleProduct;
